import { render, staticRenderFns } from "./slider-for-comments.vue?vue&type=template&id=7caef84c&scoped=true"
import script from "./slider-for-comments.vue?vue&type=script&lang=js"
export * from "./slider-for-comments.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7caef84c",
  null
  
)

export default component.exports