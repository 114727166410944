
export default {
  props: {
    id: String,

    loopThisSlider: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      swiperOptions: {
        keyboard: true,
        slidesPerView: 2.5,
        spaceBetween: 24,
        loop: this.loopThisSlider,
        autoplay: {
          delay: 50000,
          disableOnInteraction: true,
        },
        breakpoints: {
          0: {
            slidesPerView: 1.1,
          },
          720: {
            slidesPerView: 2.5,
          },
        },
      },
    };
  },
};
