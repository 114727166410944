
import { mapState, mapGetters } from "vuex";
import { throttle } from "~/common/helpers";

export default {
  props: [
    "id",
    "loopThis",
    "mobArrow",
    "mobPagi",
    "initialSlide",
    "profilesList",
    "isHowControls",
  ],

  data() {
    return {
      swiperOptions: {
        keyboard: true,
        initialSlideLocal: this.initialSlide,
        initialSlide:
          this.initialSlideLocal == undefined
            ? (this.initialSlideLocal = 0)
            : this.initialSlideLocal,
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: 24,
        loop: this.loopThis,
        navigation: {
          nextEl: `.swiper-next-${this.id}`,
          prevEl: `.swiper-prev-${this.id}`,
        },
        pagination: {
          el: `.swiper-pagination-${this.id}`,
          clickable: true,
          dynamicBullets: true,
          dynamicMainBullets: 3,
        },
        breakpoints: {
          320: {
            slidesPerView: 1.4,
            slidesPerGroup: 1,
            spaceBetween: 12,
            centeredSlides: true,
            centeredSlidesBounds: true,
          },
          600: {
            slidesPerView: 2.4,
            slidesPerGroup: 1,
          },
          1240: {
            slidesPerView: 4,
            slidesPerGroup: 4,
          },
        },
      },
    };
  },

  computed: {
    ...mapState(["profiles"]),

    ...mapGetters({
      discreetMode: "getDiscreetStatus",
      isMobile: "global/getMobileStatus",
    }),

    data() {
      return this.profiles.profilesList;
    },
  },

  methods: {
    reachBeginning() {
      this.$refs.cont.classList.remove("end");
    },
    reachEnd() {
      this.$refs.cont.classList.add("end");
    },
    onSliderMove: throttle(() => {
      document.body.dispatchEvent(new Event("click"));
    }, 1000),
  },
};
