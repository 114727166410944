
import vClickOutside from "v-click-outside";
import { mapState, mapGetters } from "vuex";
import { getCroppedImageUrl } from "~/common/helpers";
import { mdiMessageDraw } from "@mdi/js";
import { spacesToDashes } from "~/common/helpers";
import ProfileCardV2 from "~/components/v2/profile-card/profile-card.vue";

function profilesToJsonLdCollection(
  profiles,
  collectionName,
  collectionUrl,
  baseUrlApp
) {
  if (!profiles || !profiles.length) return;
  return {
    "@context": "https://schema.org",
    "@type": "ItemList",
    url: collectionUrl,
    name: collectionName,
    itemListElement: profiles.map((profile, index) => {
      return {
        "@type": "ListItem",
        position: index + 1,
        url: `${baseUrlApp}/profile/${profile.slug}`,
        name: profile.name,
        image: profile.mainPhoto,
      };
    }),
  };
}

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },

  components: {
    ProfileCardV2,
  },

  filters: {
    capitalizLetter(value) {
      if (value.length) {
        return value
          .split(" ")
          .map((part) => {
            return part[0].toUpperCase() + part.slice(1);
          })
          .join(" ");
      }
    },
  },

  layout: "splash",

  data() {
    return {
      mdiMessageDraw,
      modalChangeLocation: false,
    };
  },

  async fetch({ store }) {
    await store.dispatch("sliders/getMainPageData");
    await store.dispatch("profiles/retrieveFilters");
  },

  head() {
    return {
      title: this.seoTexts?.title || this.$t("seo.main.title"),

      link: [
        {
          rel: "canonical",
          href: this.$config.baseUrlApp,
        },
      ],

      meta: [
        {
          hid: "description",
          name: "description",
          content:
            this.seoTexts?.description || this.$t("seo.main.description"),
        },
        {
          hid: "og:title",
          name: "og:title",
          content: this.seoTexts?.title || this.$t("seo.main.og-title"),
        },
        {
          hid: "og:description",
          name: "og:description",
          content:
            this.seoTexts?.description || this.$t("seo.main.og-description"),
        },
      ],

      script: [
        {
          type: "application/ld+json",
          innerHTML: JSON.stringify(this.jsonldCommonCollection),
        },
      ],

      __dangerouslyDisableSanitizers: ["script"],
    };
  },

  computed: {
    ...mapState(["profiles"]),

    ...mapGetters({
      statusesSlider: "sliders/statusesSlider",
      reviewsSlider: "sliders/getLastReviews",
      discreetMode: "getDiscreetStatus",
      isMobile: "global/getMobileStatus",
      currentCity: "global/getCurrentCity",
      currentDistrict: "global/getCurrentDistrict",
      viewedProfiles: "sliders/getViewedProfiles",
      seoTexts: "global/getSeoTexts",

      girlOfTheDayData: "sliders/getGirlOfTheDayData",
      qmCarouselData: "sliders/getQmCarouselData",
      femaleCarouselData: "sliders/getFemaleCarouselData",
      newCarouselData: "sliders/getNewCarouselData",
      transgenderCarouselData: "sliders/getTransgenderCarouselData",
      maleCarouselData: "sliders/getMaleCarouselData",
      dubaiAllCount: "sliders/getDubaiAllCount",
      filtersList: "profiles/getFiltersList",
    }),

    data() {
      return this.profiles.profilesList;
    },

    dubaiDistricts() {
      return (this.filtersList?.districts || []).reduce((acc, item) => {
        if (item.city.title === "Dubai") {
          acc.push(item);
        }
        return acc;
      }, []);
    },

    splashBg() {
      // random splash-1 or splash-2
      const version = Math.floor(Math.random() * 2) + 1;
      return require(`~/assets/video/splash-${version}.mp4`);
    },

    h1() {
      return this.seoTexts?.h1 || this.$t("splash.main-slogan");
    },

    jsonldCommonCollection() {
      return profilesToJsonLdCollection(
        [
          ...this.qmCarouselData,
          ...this.femaleCarouselData,
          ...this.newCarouselData,
          ...this.transgenderCarouselData,
          ...this.maleCarouselData,
        ],
        "Emirate escorts",
        this.$config.baseUrlApp,
        this.$config.baseUrlApp
      );
    },
  },

  watch: {
    modalChangeLocation() {
      this.modalChangeLocation
        ? (document.body.style.overflow = "hidden")
        : (document.body.style.overflow = "unset");
    },
  },

  beforeDestroy() {
    window.removeEventListener("scroll", this.effectScroll);
  },

  beforeMount() {
    this.$store.dispatch("global/retrieveLocation");
    window.addEventListener("scroll", this.effectScroll, {
      passive: true,
    });
  },

  methods: {
    effectScroll() {
      let pxlCount = window.scrollY / 90;

      if (pxlCount < 6) {
        this.$refs.bgVideo.style.webkitFilter = `blur(${pxlCount}px`;
      }
      if (this.$refs.scrollBtn) {
        this.$refs.scrollBtn.classList.toggle("hidded", window.scrollY > 250);
      }
    },

    scrollToProfiles() {
      const sliders = document.querySelector(".sliders");
      const slidersTop = sliders.getBoundingClientRect().top + window.scrollY;
      const offsetHeader = 80;
      window.scrollTo({
        top: slidersTop - offsetHeader,
        behavior: "smooth",
      });
    },

    openedModal() {
      this.$store.commit("global/setModalChangeCityStatus");
    },

    goToCatalog() {
      this.$router.push({
        path: "/dubai/female",
      });
    },

    getCroppedImageUrl(fullImageUrl, size, discreetMode) {
      return getCroppedImageUrl(fullImageUrl, size, discreetMode);
    },

    districtLink(districtTitle) {
      return `dubai-${spacesToDashes(districtTitle)}/female`;
    },
  },
};
